export enum TokenType {
  WHITESPACE = 'WHITESPACE',
  NEW_LINE = 'NEW_LINE',
  VISUAL_NEW_LINE = 'VISUAL_NEW_LINE',
  COMMENT = 'COMMENT',
  OPEN_BLOCK_COMMENT = 'OPEN_BLOCK_COMMENT',
  CLOSE_BLOCK_COMMENT = 'CLOSE_BLOCK_COMMENT',
  SLASH = 'SLASH',
  SINGLE_QUOTE = 'SINGLE_QUOTE',
  DOUBLE_QUOTE = 'DOUBLE_QUOTE',
  BACKTICK = 'BACKTICK',
  OPEN_PAREN = 'OPEN_PAREN',
  CLOSE_PAREN = 'CLOSE_PAREN',
  OPEN_BRACE = 'OPEN_BRACE',
  CLOSE_BRACE = 'CLOSE_BRACE',
  OPEN_DOUBLE_BRACKET = 'OPEN_DOUBLE_BRACKET',
  CLOSE_DOUBLE_BRACKET = 'CLOSE_DOUBLE_BRACKET',
  COMMA = 'COMMA',
  COLON = 'COLON',
  AT = 'AT',
  PIPE = 'PIPE',
  ARROW = 'ARROW',
  KW_CALL = 'KW_CALL',
  KW_EVAL = 'KW_EVAL',
  KW_MODE = 'KW_MODE',
  KW_USE = 'KW_USE',
  KW_MATCH = 'KW_MATCH',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
}
