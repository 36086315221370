{
  "name": "bml",
  "version": "0.0.35",
  "description": "A stochastic markup language",
  "author": {
    "name": "Andrew Yoon",
    "email": "andrew@nothing-to-say.org",
    "url": "https://andrewyoon.art"
  },
  "man": "./man/bml.1",
  "repository": {
    "type": "git",
    "url": "https://github.com/ajyoon/bml"
  },
  "bugs": {
    "url": "https://github.com/ajyoon/bml/issues"
  },
  "source": "src/bml.ts",
  "main": "dist/index.js",
  "bundle": "dist/bml.bundle.js",
  "cli": "dist/cli.js",
  "targets": {
    "main": {
      "optimize": true
    },
    "bundle": {
      "source": "src/browserIndex.js",
      "optimize": true,
      "outputFormat": "global",
      "context": "browser",
      "isLibrary": false
    },
    "cli": {
      "source": "src/cli.ts",
      "optimize": true,
      "sourceMap": false,
      "context": "node",
      "isLibrary": false
    }
  },
  "bin": {
    "bml": "dist/cli.js"
  },
  "scripts": {
    "test": "jest",
    "releaseTest": "node test/releaseTest.js",
    "checkTypes": "./node_modules/.bin/tsc --noEmit",
    "buildOnly": "./node_modules/.bin/parcel build",
    "build": "npm run checkTypes && npm run buildOnly && npm run releaseTest",
    "preversion": "npm test",
    "version": "npm run build"
  },
  "devDependencies": {
    "@parcel/transformer-typescript-types": "^2.0.1",
    "@swc/core": "^1.2.119",
    "@swc/jest": "^0.2.14",
    "@types/jest": "^27.0.3",
    "@types/sha.js": "^2.4.0",
    "jest": "^27.4.4",
    "parcel": "^2.0.1",
    "sha.js": "2.4.11",
    "typescript": "^4.5.4"
  },
  "dependencies": {
    "seedrandom": "2.4.4"
  },
  "license": "BSD-3-Clause"
}
